import * as api from "@bakkt/api";
import { ApiPortalRolePermissionsEnum, ApiReportListRequestReportTypeEnum } from "@bakkt/api";
import { PortalPermission, PortalRole, User } from "./AppState";
export { PortalPermission, PortalRole };

export const PORTAL_PERMISSIONS_LIST = Object.entries(api.ApiPortalRolePermissionsEnum).map((e) => e[1]);

class PermissionManager {
  currentUser: User | null = null;

  /*
	returns boolean whether user has ANY of the required permissions
	@param userPermissions - array containing the user's permissions
	@param requiredPermissions - array containing permissions that are allowed to access
	*/
  canAccess(userPermissions: PortalPermission[], requiredPermissions: PortalPermission[]) {
    if (userPermissions.find((userPermission) => requiredPermissions.indexOf(userPermission) >= 0) != null) return true;
    return false;
  }

  /*
	state-dependent wrapper around canAccess that uses the user currently in the store
	returns boolean whether the operation is allowed for the user
	@param requiredPermissions - any allowed permissions
	*/
  userAllowed(requiredPermissions: PortalPermission[]) {
    const user = this.getCurrentUser();
    if (user == null) return false;
    return this.canAccess(user.permissions, requiredPermissions);
  }

  getCurrentUser() {
    return this.currentUser;
  }

  setCurrentUser(user: User) {
    this.currentUser = user;
  }

  //feature specific

  // Returns boolean whether user has ANY of the required gift card permissions
  isAnyGCAdminPermission() {
    return this.userAllowed(Object.values(PortalPermission).filter((p) => p.indexOf("giftcard") >= 0));
  }

  // Returns boolean whether user has ANY of the required CSR permissions
  isAnyCSRAdminPermission() {
    return this.userAllowed(Object.values(PortalPermission));
  }

  canAccessBakktCard() {
    return this.userAllowed([PortalPermission.BAKKT_CARD_VIEW]);
  }

  canUpdateBakktCard() {
    return this.userAllowed([PortalPermission.BAKKT_CARD_UPDATE]);
  }

  canUpdateCardIssuerKyc() {
    return this.userAllowed([PortalPermission.CARD_ISSUER_KYC_UPDATE]);
  }

  canAccessGiftCard() {
    return this.userAllowed([PortalPermission.GIFTCARD_VIEW_ACTIVITY]);
  }

  canViewPIIData() {
    return this.userAllowed([PortalPermission.PARTY_PII_VIEW]);
  }
  canAccessSignInDetails() {
    return this.userAllowed([PortalPermission.DEVICE_SESSION_VIEW]);
  }
  canAccessAccountActivity() {
    return this.userAllowed([PortalPermission.ACCOUNT_ACTIVITY_VIEW]);
  }
  canAccessIncentives() {
    return this.userAllowed([PortalPermission.PARTY_VIEW]);
  }
  canAccessAssetActivity() {
    return this.userAllowed([PortalPermission.TRANSACTIONS_VIEW]);
  }
  canAccessVerification() {
    return this.userAllowed([PortalPermission.KYC_ACTIVITY_VIEW]);
  }
  canDoPartySuspend() {
    return this.userAllowed([PortalPermission.PARTY_SUSPEND]);
  }
  canDoPartyActivate() {
    return this.userAllowed([PortalPermission.PARTY_ACTIVATE]);
  }
  canDoPartyLiquidate() {
    return this.userAllowed([PortalPermission.PARTY_LIQUIDATE]);
  }
  canDoPartyClose() {
    return this.userAllowed([PortalPermission.PARTY_CLOSE]);
  }
  canViewGiftCardBrandActivity() {
    return this.userAllowed([PortalPermission.GIFTCARD_BRAND_VIEW_ACTIVITY]);
  }

  canAccessAchPProcessingReport() {
    return this.userAllowed([PortalPermission.REPORT_ACH_PROCESSING_VIEW]);
  }
  canAccessWhitelistReport() {
    return this.userAllowed([PortalPermission.PARTY_WHITELIST_UPDATE]);
  }
  canCreateIncentiveProgram() {
    return this.userAllowed([PortalPermission.INCENTIVE_OFFER_CREATE]);
  }
  canAccessLinkedAchView() {
    return this.userAllowed([PortalPermission.PLAID_ACCOUNT_DETAIL_VIEW]);
  }
  canAccessLinkedDebitCardView() {
    return this.userAllowed([PortalPermission.DEBIT_CARD_VIEW]);
  }
  canAccessLoyaltyAccountView() {
    return this.userAllowed([PortalPermission.LOYALTY_ACCOUNT_VIEW]);
  }
  canAccessLinkedView() {
    return this.canAccessLinkedAchView() || this.canAccessLinkedDebitCardView() || this.canAccessLoyaltyAccountView();
  }
  canAccessActivityAuditView() {
    return this.userAllowed([PortalPermission.ACTIVITY_AUDIT_VIEW]);
  }
  isReportViewPermissioned(reportType: ApiReportListRequestReportTypeEnum) {
    return this.userAllowed([("REPORT_" + reportType + "_VIEW") as ApiPortalRolePermissionsEnum]);
  }
  isAnyReportPermissioned() {
    const user = this.getCurrentUser();
    if (user == null) return false;
    return user.permissions.filter((p) => p.startsWith("REPORT"));
  }

  isAnyReportViewPermissioned() {
    const user = this.getCurrentUser();
    if (user == null) return false;
    return user.permissions.filter((p) => p.startsWith("REPORT") && p.endsWith("VIEW"));
  }

  canViewRiskHistoryDetail() {
    return this.userAllowed([PortalPermission.RISK_AUDIT_VIEW_DETAIL]);
  }

  canViewRiskHistory() {
    return this.userAllowed([PortalPermission.RISK_AUDIT_VIEW]);
  }

  canViewPlaidAccountDetail() {
    return this.userAllowed([PortalPermission.PLAID_ACCOUNT_DETAIL_VIEW]);
  }

  canUnmaskPlaidAccNumbers() {
    return this.userAllowed([PortalPermission.UNMASK_PLAID_ACCOUNT_NUMBERS]);
  }

  canUnmaskDebitCardFields() {
    return this.userAllowed([PortalPermission.UNMASK_DEBIT_CARD_FIELDS]);
  }
  canViewSiftErrors() {
    return this.userAllowed([PortalPermission.TRANSACTION_ACCOUNT_LINK_FAILURES_VIEW]);
  }

  canViewPartnerDetail() {
    return this.userAllowed([PortalPermission.PARTNER_DETAIL_VIEW]);
  }

  canLinkUnlinkPartner() {
    return this.userAllowed([PortalPermission.PARTNER_LINK_UNLINK]);
  }

  canChangePartyStatus() {
    return this.userAllowed([PortalPermission.PARTY_STATUS_UPDATE]);
  }

  canViewTaxId() {
    return (
      this.userAllowed([PortalPermission.TAXID_LAST_4_DIGIT_VIEW]) ||
      this.userAllowed([PortalPermission.TAXID_FULL_VIEW])
    );
  }

  isUserAdminOrCompliance() {
    const user = this.getCurrentUser();
    const adminUser = user.roles.includes(PortalRole.ADMIN);
    const complianceUser = user.roles.includes(PortalRole.COMPLIANCE_FULL);
    return !!(adminUser || complianceUser);
  }
}

export default new PermissionManager();
